@import "mixins"
.nav
  height: 100%
  display: inline-flex
  margin: 0 auto
  min-width: 510px
  a
    min-width: 58px
    font-size: 12px
    color: rgb(var(--base_textColor))
    padding: 0 12px 1px
    @include flex
    justify-content: center
    position: relative
    white-space: nowrap
    text-transform: capitalize

    &:not(:first-of-type)
      margin-left: 24px

    &:hover
      color: rgb(var(--thirdaryColor))

    .bonusesIcon
      margin-left: 10px

  @media only screen and (max-width: 1360px)
    a:not(:first-of-type)
      margin-left: 14px

.predictBtcModal :global(.modal)
  border-radius: 4px
  overflow: hidden
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5)
  width: 663px
  height: 524px
  background: linear-gradient(to bottom, #1c213e 0%, #161932 100%)
  > i
    width: 22px
    height: 22px
    border-radius: 100%
    background: #262c52
    right: 10px
    top: 7px
  &:before
    content: ""
    position: absolute
    left: 0
    top: 0
    background: var(--icon-bet-on-btc-bg) no-repeat top center
    width: 100%
    height: 100%
  .predictBtcPriceModalLoader
    background: linear-gradient(to bottom, #1c213e 0%, #161932 100%)
@keyframes pulse
  100%
    transform: scale(1.75)
    opacity: 0
